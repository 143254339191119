<template>
  <v-card flat>
    <v-card-text>
      <v-select
        v-model="value.currentLocationId"
        :items="locations"
        label="Select Location of this Layout"
        outlined
      />
    </v-card-text>
    <v-card-text v-if="editLocationId">
      <v-alert
        dense
        outlined
        border="left"
        type="warning"
        class="mb-0"
      >
        The data of the location updated here will reflect on all other screens.
      </v-alert>
      <validation-observer
        ref="itemForm"
        v-slot="{ valid }"
        tag="form"
      >
        <v-card :loading="loadingItem">
          <v-card-title>
            <span class="headline">Edit Location Information</span>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-4">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Company Title"
            >
              <v-text-field
                v-model="editItem.title"
                outlined
                label="Title"
                required
                :error-messages="errors"
              />
            </validation-provider>
            <v-hover
              v-slot="{ hover }"
              class="mb-8"
            >
              <v-card
                outlined
                :style="{ borderColor: hover ? 'black' : null }"
              >
                <v-subheader>
                  Special Brand Image
                </v-subheader>
                <v-card-text class="pt-0">
                  <asset-manager v-model="editItem.specialMarke" />
                </v-card-text>
              </v-card>
            </v-hover>
            <v-textarea v-model="editItem.address" outlined auto-grow rows="2" label="Address" />
            <v-text-field v-model="editItem.phone" outlined label="Phone" />
            <v-text-field v-model="editItem.fax" outlined label="Fax" />
            <v-text-field v-model="editItem.email" outlined label="Email" />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              :loading="savingItem"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { db } from '../../../plugins/firebase'
export default {
  name: 'MAnsprechpartnerForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    editLocationId: '',
    editItem: {},
    loadingItem: false,
    savingItem: false,
    locations: []
  }),
  watch: {
    async editLocationId(id) {
      if (id) {
        this.loadingItem = true
        const item = await db.collection('dresen-standorte').doc(id).get()
        if (item.exists) {
          this.editItem = Object.assign({}, item.data())
        } else {
          this.editItem = {}
        }
        this.loadingItem = false
      }
    }
  },
  async mounted() {
    const { docs } = await db.collection('dresen-standorte-renew').get()
    this.locations = docs.map(x => ({ value: x.id, text: x.data().uri }))
  },
  methods: {
    async save() {
      this.savingItem = true
      await db.collection('dresen-standorte').doc(this.editLocationId).set(this.editItem)
      this.savingItem = false
      this.editLocationId = ''
    }
  }
}
</script>
